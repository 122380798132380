// Importing the emailTemplateActionType from ../actionTypes.js file.

import { emailTemplateActionType } from "../actionTypes"

// Declaring the initial state of our emailTemplate reducer.

const initialState = {
  emailTemplates: [],
  isUpdateEmailTemplateSuccess: false,
  errorEmailTemplate: "",
  isGetEmailTemplatesSuccess: false
}

// Defining the emailTemplate reducer function.

const emailTemplate = (state = initialState, action) => {
  switch (action.type) {

    // If the action type is GET_LIST_EMAIL_TEMPLATE, we update the emailTemplates array in the state.
    case emailTemplateActionType.GET_LIST_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplates: action.payload,
      }

    // If the action type is UPDATE_EMAIL_TEMPLATE_SUCCESS, we update the isUpdateEmailTemplateSuccess boolean flag in the state.
    case emailTemplateActionType.UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isUpdateEmailTemplateSuccess: action.payload,
      }

    // If the action type is ERROR_EMAIL_TEMPLATE, we update the errorEmailTemplate string in the state.
    case emailTemplateActionType.ERROR_EMAIL_TEMPLATE:
      return {
        ...state,
        errorEmailTemplate: action.payload,
      }

    // If the action type is GET_EMAIL_TEMPLATES_SUCCESS, we update the isGetEmailTemplatesSuccess boolean flag in the state.
    case emailTemplateActionType.GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        isGetEmailTemplatesSuccess: action.payload,
      }

    // If the action type doesn't match any of the cases above, we simply return the unchanged state.
    default:
      return state
  }
}

// Exporting the emailTemplate reducer function as default.
export default emailTemplate
