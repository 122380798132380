// Importing the testTypeActionType actionTypes from "../actionTypes"
import { testTypeActionType } from "../actionTypes";

// Setting the initialState for the reducer
const initialState = {
  isUpdateTestTypeSuccess: false,
  errorTestType: "",
  isAddTestTypeSuccess: false,
  isDeleteTestTypeSuccess: false,
}

// Creating the reducer function
const testType = (state = initialState, action) => {
  switch (action.type) {

    // Update state based on type UPDATE_TEST_TYPE_SUCCESS
    case testTypeActionType.UPDATE_TEST_TYPE_SUCCESS:
      return {
        ...state,
        isUpdateTestTypeSuccess: action.payload,
      }

    // Update state based on type ERROR_TEST_TYPE
    case testTypeActionType.ERROR_TEST_TYPE:
      return {
        ...state,
        errorTestType: action.payload,
      }

    // Update state based on type ADD_TEST_TYPE_SUCCESS
    case testTypeActionType.ADD_TEST_TYPE_SUCCESS:
      return {
        ...state,
        isAddTestTypeSuccess: action.payload
      }

    // Update state based on type DELETE_TEST_TYPE_SUCCESS
    case testTypeActionType.DELETE_TEST_TYPE_SUCCESS:
      return {
        ...state,
        isDeleteTestTypeSuccess: action.payload
      }

    // Return the initial state if action.type is not recognised
    default:
      return state
  }
}

// Exporting the reducer function
export default testType;
