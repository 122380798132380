// Import the axios library
import axios from 'axios'

// Set the default baseURL for all requests to a value specified in the environment variables
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT

// Add an interceptor to the request pipeline
axios.interceptors.request.use(
  // This function is called before every request is sent
  function (axios_config: any) {
    // Set the Authorization header to include the user's token from local storage
    axios_config.headers.Authorization = "Bearer " + localStorage.getItem('token')
    // Return the updated configuration object so that the request continues as planned
    return axios_config
  },
  // This function is called if an error occurs during the request pipeline
  function (error) {
    // Do something with the error (in this case, just reject the promise)
    return Promise.reject(error)
  }
)

// Export the axios library for use in other modules
export default axios
