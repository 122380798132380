import { combineReducers } from 'redux'
import auth from './auth'
import app from './app'
import business from './business'
import alert from './alert'
import project from './project'
import testGroup from './testGroup'
import testType from './testType'
import modal from './modal'
import material from './material'
import notification from './notification'
import testingRequest from './testingRequest'
import emailTemplate from './emailTemplate'
import tracking from './tracking'
import laboratories from './laboratories'
import enquiry from './enquiry'
import upload from './upload'
import masterData from './masterData'
import userSetting from './userSetting'
import testingReport from './testingReport'
import comment from './comment'
import history from './history'
import ags4 from './ags4'

export default combineReducers({
    auth,
    app,
    business,
    alert,
    project,
    testGroup,
    testType,
    modal,
    material,
    notification,
    testingRequest,
    emailTemplate,
    tracking,
    laboratories,
    enquiry,
    upload,
    masterData,
    userSetting,
    testingReport,
    comment,
    history,
    ags4
})