// Importing the businessActionTypes object from "../actionTypes"
import { businessActionType } from "../actionTypes";

// This is the default values for the registerUserData state property
const defaultData = {
  business_name: "",
  business_number: "",
  gst: "",
  address_1: "",
  address_2: "",
  country: "New Zealand",
  zip_code: "",
  state: "",
  city: "",
  admin_name: "",
  admin_position: "",
  admin_phone_number: "",
  admin_email: "",
  suburb: "",

  // These are the is_billing_address_same and is_shipping_address_same properties. They will be set to true by default
  is_billing_address_same: "true",
  billing_address_1: "",
  billing_address_2: "",
  billing_country: "New Zealand",
  billing_zip_code: "",
  billing_state: "",
  billing_city: "",
  billing_suburb: "",

  is_shipping_address_same: "true",
  shipping_address_1: "",
  shipping_address_2: "",
  shipping_country: "New Zealand",
  shipping_zip_code: "",
  shipping_state: "",
  shipping_city: "",
  shipping_suburb: "",

  business_username: "",
  business_prefix: ""
};

// This is the initial state that is being defined in the Reducer
const initialState = {
  registerUserData: defaultData,
  isRegisterSuccess: false,
  errorRegister: "",

  businesses: [],
  business: {},
  users: [],
  isUpdateBusinessAccountSuccess: false,
  errorBusinessAccount: "",
  isResetPasswordSuccess: false,
  isGetUsersSuccess: false,
  isGetBusinessesSuccess: false,

  user: {},
  isUpdateUserAccountSuccess: false,
};

const business = (state = initialState, action) => {
  switch (action.type) {
    case businessActionType.GET_LIST:
      return {
        ...state,
        businesses: action.payload,
      };
    case businessActionType.GET_BY_ID:
      return {
        ...state,
        business: action.payload,
      };
    case businessActionType.GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case businessActionType.UPDATE_BUSINESS_ACCOUNT_SUCCESS:
      return {
        ...state,
        isUpdateBusinessAccountSuccess: action.payload,
      };
    case businessActionType.ERROR_BUSINESS_ACCOUNT:
      return {
        ...state,
        errorBusinessAccount: action.payload,
      };
    case businessActionType.RESET_PASSWORD_BUSINESS:
      return {
        ...state,
        users: action.payload,
      };
    case businessActionType.RESET_PASSWORD_BUSINESS_SUCCESS:
      return {
        ...state,
        isResetPasswordSuccess: action.payload,
      };
    case businessActionType.INIT_REGISTER_DATA:
      return {
        ...state,
        isRegisterSuccess: false,
        registerUserData: defaultData,
      };
    case businessActionType.REGISTER_USER_DATA:
      return {
        ...state,
        registerUserData: action.payload,
      };
    case businessActionType.REGISTER_SUCCESS:
      return {
        ...state,
        isRegisterSuccess: action.payload,
      };
    case businessActionType.ERROR_REGISTER:
      return {
        ...state,
        errorRegister: action.payload,
      };
    case businessActionType.GET_USERS_SUCCESS:
      return {
        ...state,
        isGetUsersSuccess: action.payload,
      };
    case businessActionType.GET_BUSINESSES_SUCCESS:
      return {
        ...state,
        isGetBusinessesSuccess: action.payload,
      };
    case businessActionType.GET_USER_BY_ID:
      return {
        ...state,
        user: action.payload,
      };
    case businessActionType.UPDATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isUpdateUserAccountSuccess: action.payload,
      };
    default:
      return state;
  }
};

// Exporting the business reducer function as the default export
export default business;
