// Importing authActionType from "../actionTypes" module.
import { authActionType } from "../actionTypes";

// Defining defaultSizeData object with width, height and quantity properties.
const defaultSizeData = {
  width: "380",
  height: "204",
  quantity: "1"
};

// Defining initial state object with various properties.
const initialState = {
  registerSizeData: defaultSizeData,
  token: "",
  refreshToken: "",
  rememberMe: false,
  isLoginSuccess: false,
  isRememberMe: false,
  errorFirstTimeResetPassword: "",
  isFirstTimeResetPasswordSuccess: false,
  firstTimeResetPasswordData: {
    password: "",
    new_password: "",
    email: "",
    need_reset: false,
  },
  errorLogin: "",
  errorAddSize: "",
  isAddSizeSuccess: false,
  isRegisterSizeSuccess: false,
  size: {},
};

// Define auth reducer function with state and action as parameters.
const auth = (state = initialState, action) => {
  switch (action.type) {
    // Case for LOGIN action type.
    case authActionType.LOGIN:
      return {
        ...state,
      };
    // Case for LOGIN_SUCCESS action type.
    case authActionType.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginSuccess: action.payload,
      };
    // Case for REMEMBER_ME action type.
    case authActionType.REMEMBER_ME:
      return {
        ...state,
        isRememberMe: true,
      };
    // Case for LOGOUT action type.
    case authActionType.LOGOUT:
      return {
        ...state,
        isLoginSuccess: false,
      };
    // Case for ERROR_LOGIN action type.
    case authActionType.ERROR_LOGIN:
      return {
        ...state,
        errorLogin: action.payload,
      };
    // Case for CLEAR_ERROR_MESSAGE action type.
    case authActionType.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorLogin: "",
        errorRegister: "",
      };
    // Case for GET_SIZE_FOR_PRINT action type.
    case authActionType.GET_SIZE_FOR_PRINT:
      return {
        ...state,
        size: action.payload,
      };
    // Case for INIT_SIZE_DATA action type.
    case authActionType.INIT_SIZE_DATA:
      return {
        ...state,
        isRegisterSuccess: false,
        registerSizeData: defaultSizeData,
      };
    // Case for REGISTER_SIZE_DATA action type.
    case authActionType.REGISTER_SIZE_DATA:
      return {
        ...state,
        registerSizeData: action.payload,
      };
    // Case for REGISTER_SIZE_SUCCESS action type.
    case authActionType.REGISTER_SIZE_SUCCESS:
      return {
        ...state,
        isRegisterSizeSuccess: action.payload,
      };
    // Case for ERROR_REGISTER_SIZE action type.
    case authActionType.ERROR_REGISTER_SIZE:
      return {
        ...state,
        errorRegisterSize: action.payload,
      };
    // Case for SET_FIRST_TIME_RESET_PASSWORD_DATA action type.
    case authActionType.SET_FIRST_TIME_RESET_PASSWORD_DATA:
      return {
        ...state,
        firstTimeResetPasswordData: action.payload,
      };
    // Case for FIRST_TIME_RESET_PASSWORD_SUCCESS action type.
    case authActionType.FIRST_TIME_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFirstTimeResetPasswordSuccess: action.payload,
      };
    // Case for ERROR_FIRST_TIME_RESET_PASSWORD action type.
    case authActionType.ERROR_FIRST_TIME_RESET_PASSWORD:
      return {
        ...state,
        errorFirstTimeChangePassword: action.payload,
      };
    // Default fallback case returns the current state.
    default:
      return state;
  }
};

// Exporting auth reducer function as default.
export default auth;
