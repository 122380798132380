//Importing the action types used in history reducer.
import { historyActionType } from "store/actionTypes";

//Initial State of histories.
const initialState = {
  histories: [],
  // Initial State of selected history object to show.
  history: {},
  isGetListHistorySuccess: false,
  isGetListHistoryError: false,
  isGetDataHistorySuccess: false,
  isGetDataHistoryError: false,
  isUpdateHistorySuccess: false,
  isUpdateHistoryError: false,
};

//History reducer function definition which accepts previous state and an action to perform.
const history = (state = initialState, action) => {
  switch (action.type) {
    // Get list of histories from API.
    case historyActionType.GET_LIST_HISTORY:
      // Return new state object after making a copy of previous state object and updating histories array with payload received from API.
      return {
        ...state,
        histories: action.payload
      }
    // When getting list of histories from API is successful.
    case historyActionType.GET_LIST_HISTORY_SUCCESS:
      // Return new state object after making a copy of previous state object and setting isGetListHistorySuccess flag to true.
      return {
        ...state,
        isGetListHistorySuccess: action.payload,
      }
    // When getting list of histories from API fails.
    case historyActionType.GET_LIST_HISTORY_ERROR:
      // Return new state object after making a copy of previous state object and setting isGetListHistoryError flag to true.
      return {
        ...state,
        isGetListHistoryError: action.payload,
      }
    // Get data of a specific history from API.
    case historyActionType.GET_DATA_HISTORY_BY_ID:
      // Return new state object after making a copy of previous state object and updating history object with payload received from API.
      return {
        ...state,
        history: action.payload
      }
    // When getting history data from API is successful.
    case historyActionType.GET_DATA_HISTORY_BY_ID_SUCCESS:
      // Return new state object after making a copy of previous state object and setting isGetDataHistorySuccess flag to true.
      return {
        ...state,
        isGetDataHistorySuccess: action.payload,
      }
    // When getting history data from API fails.
    case historyActionType.GET_DATA_HISTORY_BY_ID_ERROR:
      // Return new state object after making a copy of previous state object and setting isGetDataHistoryError flag to true.
      return {
        ...state,
        isGetDataHistoryError: action.payload,
      }
    // Update data of a specific history to API.
    case historyActionType.UPDATE_DATA_HISTORY_SUCCESS:
      // Return new state object after making a copy of previous state object and setting isUpdateHistorySuccess flag to true.
      return {
        ...state,
        isUpdateHistorySuccess: action.payload
      }
    // When updating history data to API fails.
    case historyActionType.UPDATE_DATA_HISTORY_ERROR:
      // Return new state object after making a copy of previous state object and setting isUpdateHistoryError flag to true.
      return {
        ...state,
        isUpdateHistoryError: action.payload,
      };
    // If action type doesn't match any of the above cases, return the previous state object.
    default:
      return state
  }
}

//Exporting the history reducer function as default.
export default history;
