import { notificationActionType } from "../actionTypes";

// Define initial state for notifications
const initialState = {
  notifications: [],
  notification: {},
  isUpdateNotificationSuccess: false,
  isReadNotifications: [],
  isGetNotificationByIdSuccess: false,
  isDeleteNotificationSuccess: false,
  isUpdateNotReadNotificationSuccess: false,
};

/**
 * This code includes a reducer function that updates the state based on different actions related to notifications.
 * Each case corresponds to a specific action type and updates the state accordingly.
 * The initial state includes properties for storing a list of notifications, a single notification,
 * and various boolean values related to fetching, deleting and updating notifications.
 * 
 * @param state 
 * @param action 
 * @returns 
 */
// Create a reducer function called 'notification'
const notification = (state = initialState, action) => {
  // Add comment for each case
  switch (action.type) {

    // Case for getting list of all notifications and updating state
    case notificationActionType.GET_LIST_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload,
      };

    // Case for getting a notification by id and updating state
    case notificationActionType.GET_NOTIFICATION_BY_ID:
      return {
        ...state,
        notification: action.payload,
      };

    // Case for updating read notification status and updating state
    case notificationActionType.UPDATE_READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isUpdateNotificationSuccess: action.payload,
      };

    // Case for fetching unread notification data and updating state
    case notificationActionType.IS_READ_NOTIFICATION_DATA:
      return {
        ...state,
        isReadNotifications: action.payload,
      };

    // Case for getting a notification by id successfully and updating state
    case notificationActionType.GET_NOTIFICATION_BY_ID_SUCCESS:
      return {
        ...state,
        isGetNotificationByIdSuccess: action.payload,
      };
    
    // Case for deleting a notification successfully and updating state
    case notificationActionType.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isDeleteNotificationSuccess: action.payload,
      };
    
    // Case for updating unread notification status successfully and updating state
    case notificationActionType.UPDATE_NOT_READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isUpdateNotReadNotificationSuccess: action.payload,
      };

    // Default case if none of the above cases match
    default:
      return state;
  }
};

// Export the 'notification' reducer function as the default export
export default notification;
