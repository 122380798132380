// Import necessary modules and files
import { createStore, applyMiddleware, compose } from 'redux' // Redux modules
import thunk from 'redux-thunk' // Middleware
import rootReducer from './reducers' // Root reducer file
import API from 'service/api' // Custom API module
import API_BOOK from 'service/endpoints' // Endpoints file
import { history } from "helpers/common"; // Browser history object
import toast from "components/SMSYSToast"; // Toast component for showing messages

// Set up Redux DevTools Extension and middleware for API calls, withExtraArgument passes additional arguments to each dispatched function containing API, API_BOOK, toast and history objects.
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;
const middlewareEnhancer = applyMiddleware(
    thunk.withExtraArgument({
        API,
        API_BOOK,
        toast,
        history
    })
)

// Create store using rootReducer, no initial state, and the enhancer created above.
const store = createStore(
    rootReducer,
    undefined,
    composeEnhancers(middlewareEnhancer)
)

// Export the store by default
export default store
