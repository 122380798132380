// Forgot password steps.
export const forgotPasswordSteps = {
    // 0: send email
    'sendEmail': 0,

    // 1: change password.
    'changePassword': 1,

    // 2: success.
    'success': 2
}

/** The register steps. */
export const registerSteps = {
    // 0: business info.
    'businessInfo': 0,

    // 1: generate account.
    'generateAccount': 1,

    // 2: success.
    'success': 2
}

/** Add sample steps */
export const addSampleSteps = {
    // 0: internal
    "isInternal":0,

    // 1: project info.
    'projectInfo': 1,

    // 2: options
    'options':2,

    // 3: sample data.
    'sampleData': 3,

    // 4: confirm.
    'confirm':4,

    // 5: success.
    'success': 5,
}

/** Add internal project steps. */
export const addInternalProjectSteps = {
    // 0: project information.
    'projectInfo': 0,

    // 1: options.
    'options':1,

    // 2: sample data.
    'sampleData': 2,

    // 3: confirmation.
    'confirm':3,

    // 4: success status.
    'success': 4,
}