//The following block lists action strings for auth related actions
export const authActionType = {
  LOGIN: "LOGIN", // LOGIN" action when user logs in
  LOGIN_SUCCESS: "LOGIN_SUCCESS", // LOGIN_SUCCESS" action when login is successful
  REMEMBER_ME: "REMEMBER_ME", // REMEMBER_ME" action to remember user credentials
  LOGOUT: "LOGOUT", // LOGOUT" action to log out a user
  ERROR_LOGIN: "ERROR_LOGIN", // ERROR_LOGIN" action for login errors
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE", // CLEAR_ERROR_MESSAGE" action to clear error message on success

  REGISTER_SIZE_DATA: "REGISTER_SIZE_DATA", // REGISTER_SIZE_DATA" action to register size data for printing
  INIT_SIZE_DATA: "INIT_SIZE_DATA", // INIT_SIZE_DATA" action to initialize size data
  REGISTER_SIZE_SUCCESS: "REGISTER_SIZE_SUCCESS", // REGISTER_SIZE_SUCCESS" action when size registration is successful
  GET_SIZE_FOR_PRINT: "GET_SIZE_FOR_PRINT", // GET_SIZE_FOR_PRINT" action to get size data for printing
  ERROR_REGISTER_SIZE: "ERROR_REGISTER_SIZE", // ERROR_REGISTER_SIZE" action for errors encountered during size registration
  FIRST_TIME_RESET_PASSWORD_SUCCESS: "FIRST_TIME_RESET_PASSWORD_SUCCESS", // FIRST_TIME_RESET_PASSWORD_SUCCESS" action when reset password is successful
  ERROR_FIRST_TIME_RESET_PASSWORD: "ERROR_FIRST_TIME_RESET_PASSWORD", // ERROR_FIRST_TIME_RESET_PASSWORD" action for errors encountered while resetting password
  SET_FIRST_TIME_RESET_PASSWORD_DATA: "SET_FIRST_TIME_RESET_PASSWORD_DATA",
};

//The following block lists action strings for app level actions
export const appActionType = {
  SET_LOADING: "SET_LOADING", // SET_LOADING" action to set loading state of an app
  SET_ALERT: "SET_ALERT", // SET_ALERT" action to set alert message on app
  REMOVE_ALERT: "REMOVE_ALERT", // REMOVE_ALERT" action to remove alert message from app
  RELOAD_MESSAGE: "RELOAD_MESSAGE", // RELOAD_MESSAGE" action to reload message in app
};

//The following block lists action strings for business related actions
export const businessActionType = {
  GET_LIST: "GET_LIST", // GET_LIST" action to get a list of businesses
  GET_BY_ID: "GET_BY_ID", // GET_BY_ID" action to get a specific business by its id
  GET_USERS: "GET_USERS", // GET_USERS" action to get all users
  UPDATE_BUSINESS_ACCOUNT_SUCCESS: "UPDATE_BUSINESS_ACCOUNT_SUCCESS", // UPDATE_BUSINESS_ACCOUNT_SUCCESS" action when updating a business account is successful
  ERROR_BUSINESS_ACCOUNT: "ERROR_BUSINESS_ACCOUNT", // ERROR_BUSINESS_ACCOUNT" action for errors encountered when updating a business account
  RESET_PASSWORD_BUSINESS: "RESET_PASSWORD_BUSINESS", // RESET_PASSWORD_BUSINESS" action to reset the password of a business account
  RESET_PASSWORD_BUSINESS_SUCCESS: "RESET_PASSWORD_BUSINESS_SUCCESS", // RESET_PASSWORD_BUSINESS_SUCCESS" action when resetting the password of a business account is successful
  GET_BUSINESSES_SUCCESS: "GET_BUSINESSES_SUCCESS", // GET_BUSINESSES_SUCCESS" action when getting businesses is successful

  REGISTER_USER_DATA: "REGISTER_USER_DATA", // REGISTER_USER_DATA" action to register user data
  INIT_REGISTER_DATA: "INIT_REGISTER_DATA", // INIT_REGISTER_DATA" action to initialize registration data
  REGISTER_SUCCESS: "REGISTER_SUCCESS", // REGISTER_SUCCESS" action when registering successfully
  ERROR_REGISTER: "ERROR_REGISTER", // ERROR_REGISTER" action for errors encountered during registration
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS", // GET_USERS_SUCCESS" action when getting users is successful

  GET_USER_BY_ID: "GET_USER_BY_ID", // GET_USER_BY_ID" action to get a user by id
  UPDATE_USER_ACCOUNT_SUCCESS: "UPDATE_USER_ACCOUNT_SUCCESS", // UPDATE_USER_ACCOUNT_SUCCESS" action when updating user account is successful
};

//The following block lists action strings for project related actions
export const projectActionType = {
  REGISTER_PROJECT_DATA: "REGISTER_PROJECT_DATA", // REGISTER_PROJECT_DATA" action to register project data
  INIT_REGISTER_PROJECT_DATA: "INIT_REGISTER_PROJECT_DATA", // INIT_REGISTER_PROJECT_DATA" action to initialize registration project data
  GET_LIST: "GET_LIST", // GET_LIST" action to get a list of projects
  PROJECT_REGISTER_SUCCESS: "PROJECT_REGISTER_SUCCESS", // PROJECT_REGISTER_SUCCESS" action when registering a project is successful
  ERROR_PROJECT_REGISTER: "ERROR_PROJECT_REGISTER", // ERROR_PROJECT_REGISTER" action when there is an error while registering a project
  GET_PROJECT_BY_ID: "GET_PROJECT_BY_ID", // GET_PROJECT_BY_ID" action to get a project by its id
  ERROR_UPDATE_PROJECT: "ERROR_UPDATE_PROJECT", // ERROR_UPDATE_PROJECT" action when there is an error while updating a project
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS", // UPDATE_PROJECT_SUCCESS" action when updating a project is successful
  GET_PROJECT_BY_ID_SUCCESS: "GET_PROJECT_BY_ID_SUCCESS", // GET_PROJECT_BY_ID_SUCCESS" action when getting a project by id is successful
  SET_CURRENT_REGISTER_STEP: "SET_CURRENT_REGISTER_STEP", // SET_CURRENT_REGISTER_STEP" action to set the current registration step
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS", // GET_PROJECTS_SUCCESS" action when getting projects is successful
  ITEMS_EXIST: "ITEMS_EXIST", // ITEMS_IS_EXIST" action when check list sample id is exist
  ITEMS_NOT_EXIST: "ITEMS_NOT_EXIST", // ITEMS_NOT_EXIST" action when check list sample id isn's exist
};

//The following block lists action strings for test group related actions
export const testGroupActionType = {
  GET_LIST: "GET_LIST", // GET_LIST" action to get a list of test groups
  GET_LIST_SUCCESS: "GET_LIST_SUCCESS", // GET_LIST_SUCCESS" action when getting a list of test groups is successful
  ADD_TEST_GROUP_SUCCESS: "ADD_TEST_GROUP_SUCCESS", // ADD_TEST_GROUP_SUCCESS" action when adding a test group is successful
  ERROR_ADD_TEST_GROUP: "ERROR_ADD_TEST_GROUP", // ERROR_ADD_TEST_GROUP" action when there is an error while adding a test group
  UPDATE_TEST_GROUP_SUCCESS: "UPDATE_TEST_GROUP_SUCCESS", // UPDATE_TEST_GROUP_SUCCESS" action when updating a test group is successful
  ERROR_TEST_GROUP: "ERROR_TEST_GROUP", // ERROR_TEST_GROUP" action when there is an error while updating a test group
  DELETE_TEST_GROUP_SUCCESS: "DELETE_TEST_GROUP_SUCCESS", // DELETE_TEST_GROUP_SUCCESS" action when deleting a test group is successful
  SET_EXPAND_ROW: "SET_EXPAND_ROW", // SET_EXPAND_ROW" action to expand a row
};

//The following block lists action strings for test type related actions
export const testTypeActionType = {
  ADD_TEST_TYPE_SUCCESS: "ADD_TEST_TYPE_SUCCESS", // ADD_TEST_TYPE_SUCCESS" action when adding a test type is successful
  UPDATE_TEST_TYPE_SUCCESS: "UPDATE_TEST_TYPE_SUCCESS", // UPDATE_TEST_TYPE_SUCCESS" action when updating a test type is successful
  ERROR_TEST_TYPE: "ERROR_TEST_TYPE", // ERROR_TEST_TYPE" action when there is an error while updating a test type
  DELETE_TEST_TYPE_SUCCESS: "DELETE_TEST_TYPE_SUCCESS" // DELETE_TEST_TYPE_SUCCESS" action when deleting a test type is successful
};

//The following block lists action strings for modal related actions
export const modalActionType = {
  SET_MODAL_ADD_BARCODE_SHOW: "SET_MODAL_ADD_BARCODE_SHOW", // SET_MODAL_ADD_BARCODE_SHOW" action to show add barcode modal
  SET_MODAL_OPTION_SIZE_SHOW: "SET_MODAL_OPTION_SIZE_SHOW", // SET_MODAL_OPTION_SIZE_SHOW" action to show the size modal
  SET_MODAL_REPORT_SHOW: "SET_MODAL_REPORT_SHOW", // SET_MODAL_REPORT_SHOW" action to show report modal
  SET_MODAL_BARCODE_SHOW: "SET_MODAL_BARCODE_SHOW"
};

//The following block lists action strings for material related actions
export const materialActionType = {
  GET_LIST_MATERIAL: "GET_LIST_MATERIAL", // GET_LIST_MATERIAL" action to get a list of materials
  ADD_MATERIAL_SUCCESS: "ADD_MATERIAL_SUCCESS", // ADD_MATERIAL_SUCCESS" action when adding a material is successful
  UPDATE_MATERIAL_SUCCESS: "UPDATE_MATERIAL_SUCCESS", // UPDATE_MATERIAL_SUCCESS" action when updating a material is successful
  ERROR_MATERIAL: "ERROR_MATERIAL", // ERROR_MATERIAL" action when there is an error while updating a material
  DELETE_MATERIAL_SUCCESS: "DELETE_MATERIAL_SUCCESS", // DELETE_MATERIAL_SUCCESS" action when deleting a material is successful
  ORDER_MATERIAL_SUCCESS: "ORDER_MATERIAL_SUCCESS", // ORDER_MATERIAL_SUCCESS" action when ordering a material is successful
  CLEAR_ERROR_MATERIAL_MESSAGE: "CLEAR_ERROR_MATERIAL_MESSAGE", // CLEAR_ERROR_MATERIAL_MESSAGE" action to clear the error message for material
  GET_MATERIALS_SUCCESS: "GET_MATERIALS_SUCCESS", // GET_MATERIALS_SUCCESS" action when getting materials is successful
}; 

// The following object contains action types related to notifications
export const notificationActionType = {
  GET_LIST_NOTIFICATION: "GET_LIST_NOTIFICATION", // Action type for getting a list of notifications
  GET_NOTIFICATION_BY_ID: "GET_NOTIFICATION_BY_ID", // Action type for getting a notification by its ID
  UPDATE_READ_NOTIFICATION_SUCCESS: "UPDATE_READ_NOTIFICATION_SUCCESS", // Action type for updating a notification as read
  ERROR_UPDATE_NOTIFICATION: "ERROR_UPDATE_NOTIFICATION", // Action type for when there is an error updating a notification
  IS_READ_NOTIFICATION_DATA: "IS_READ_NOTIFICATION_DATA", // Action type for testing if a notification has been read
  GET_NOTIFICATION_BY_ID_SUCCESS: "GET_NOTIFICATION_BY_ID_SUCCESS", // Action type for when a notification is successfully retrieved
  ERROR_NOTIFICATION: "ERROR_NOTIFICATION", // Action type for when there is an error retrieving a notification
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS", // Action type for when a notification is successfully deleted
  UPDATE_NOT_READ_NOTIFICATION_SUCCESS: "UPDATE_NOT_READ_NOTIFICATION_SUCCESS", // Action type for updating an unread notification
};

// The following object contains action types related to testing requests
export const testingRequestActionType = {
  GET_TESTING_REQUESTS: "GET_TESTING_REQUESTS", // Action type for getting a list of testing requests
  GET_TESTING_REQUESTS_SUCCESS: "GET_TESTING_REQUESTS_SUCCESS", // Action type for when a list of testing requests is successfully retrieved
  CREATE_TESTING_REQUEST: "CREATE_TESTING_REQUEST", // Action type for creating a new testing request
  CREATE_TESTING_REQUEST_SUCCESS: "CREATE_TESTING_REQUEST_SUCCESS", // Action type for when a new testing request is successfully created
  GET_TESTING_REQUEST: "GET_TESTING_REQUEST", // Action type for getting a testing request by its ID
  GET_TESTING_REQUEST_SUCCESS: "GET_TESTING_REQUEST_SUCCESS", // Action type for when a testing request is successfully retrieved
  UPDATE_TESTING_REQUEST: "UPDATE_TESTING_REQUEST", // Action type for updating a testing request
  UPDATE_TESTING_REQUEST_SUCCESS: "UPDATE_TESTING_REQUEST_SUCCESS", // Action type for when a testing request is successfully updated
  GET_UNSCHEDULED_SAMPLES: "GET_UNSCHEDULED_SAMPLES", // Action type for getting a list of unscheduled samples
  GET_UNSCHEDULED_SAMPLES_SUCCESS: "GET_UNSCHEDULED_SAMPLES_SUCCESS", // Action type for when a list of unscheduled samples is successfully retrieved
  CHANGE_STATUS_SAMPLE_SUCCESS: "CHANGE_STATUS_SAMPLE_SUCCESS", // Action type for when the status of a sample is successfully changed
  RESET_STATUS_SUCCESS: "RESET_STATUS_SUCCESS", // Action type for when the status of a testing request is successfully reset
}

// The following object contains action types related to email templates
export const emailTemplateActionType = {
  GET_LIST_EMAIL_TEMPLATE: "GET_LIST_EMAIL_TEMPLATE", // Action type for getting a list of email templates
  ADD_EMAIL_TEMPLATE_SUCCESS: "ADD_EMAIL_TEMPLATE_SUCCESS", // Action type for when a new email template is successfully added
  UPDATE_EMAIL_TEMPLATE_SUCCESS: "UPDATE_EMAIL_TEMPLATE_SUCCESS", // Action type for when an email template is successfully updated
  ERROR_EMAIL_TEMPLATE: "ERROR_EMAIL_TEMPLATE", // Action type for when there is an error retrieving or updating an email template
  DELETE_EMAIL_TEMPLATE_SUCCESS: "DELETE_EMAIL_TEMPLATE_SUCCESS", // Action type for when an email template is successfully deleted
  GET_EMAIL_TEMPLATES_SUCCESS: "GET_EMAIL_TEMPLATES_SUCCESS", // Action type for when a list of email templates is successfully retrieved
};


// This defines tracking action types
export const trackingActionType = {
  GET_LIST_TRACKING: "GET_LIST_TRACKING",
  GET_LIST_TRACKING_SUCCESS: "GET_LIST_TRACKING_SUCCESS",
  GET_LIST_TRACKING_ERROR: "GET_LIST_TRACKING_ERROR",
  GET_DATA_TRACKING_PAGE: "GET_DATA_TRACKING_PAGE",
  GET_DATA_TRACKING_PAGE_SUCCESS: "GET_DATA_TRACKING_PAGE_SUCCESS",
  GET_DATA_TRACKING_PAGE_ERROR: "GET_DATA_TRACKING_PAGE_ERROR",
  GET_DATA_TESTING_REPORT: "GET_DATA_TESTING_REPORT",
  GET_DATA_TESTING_REPORT_SUCCESS: "GET_DATA_TESTING_REPORT_SUCCESS",
  UPDATE_DATA_TRACKING_PAGE: "UPDATE_DATA_TRACKING_PAGE",
  UPDATE_DATA_TRACKING_PAGE_SUCCESS: "UPDATE_DATA_TRACKING_PAGE_SUCCESS",
  DELETE_TEST_TYPE_SUCCESS: "DELETE_TEST_TYPE_SUCCESS",
}

// This defines laboratories action types
export const laboratoriesActionType = {
  GET_LIST_LABORATORY_INTERNAL: "GET_LIST_LABORATORY_INTERNAL",
  GET_LIST_LABORATORY_INTERNAL_SUCCESS: "GET_LIST_LABORATORY_INTERNAL_SUCCESS",
  GET_LIST_LABORATORY_EXTERNAL: "GET_LIST_LABORATORY_EXTERNAL",
  GET_LIST_LABORATORY_EXTERNAL_SUCCESS: "GET_LIST_LABORATORY_EXTERNAL_SUCCESS",
  GET_LIST_USER_BY_LOCATION: "GET_LIST_USER_BY_LOCATION",
  GET_LIST_USER_BY_LOCATION_SUCCESS: "GET_LIST_USER_BY_LOCATION_SUCCESS"
}

// This defines enquiry action types
export const enquiryActionType = {
  GET_LIST_ENQUIRY: "GET_LIST_ENQUIRY",
  GET_ENQUIRY_BY_ID: "GET_ENQUIRY_BY_ID",
  CREATE_ENQUIRY_SUCCESS: "CREATE_ENQUIRY_SUCCESS",
  ERROR_CREATE_ENQUIRY: "ERROR_CREATE_ENQUIRY",
  CREATE_COMMENT_ENQUIRY_SUCCESS: "CREATE_COMMENT_ENQUIRY_SUCCESS",
  GET_ENQUIRY_BY_ID_SUCCESS: "GET_ENQUIRY_BY_ID_SUCCESS",
  ERROR_ENQUIRY: "ERROR_ENQUIRY",
  ERROR_CREATE_COMMENT_ENQUIRY: "ERROR_CREATE_COMMENT_ENQUIRY",
  SOLVED_ENQUIRY_SUCCESS: "SOLVED_ENQUIRY_SUCCESS",
  GET_ENQUIRIES_SUCCESS: "GET_ENQUIRIES_SUCCESS",
};

// This defines upload action types
export const uploadActionType = {
  GET_LIST: "GET_LIST",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_PERCENT: "UPLOAD_PERCENT",
  EDIT_SUCCESS: "EDIT_SUCCESS",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  GET_TESTING_REPORTS_SUCCESS: "GET_TESTING_REPORTS_SUCCESS",
}

// This defines master data action types
export const masterDataActionType = {
  SET_MASTER_DATA: "SET_MASTER_DATA",
  GET_MASTER_DATA_SUCCESS: "GET_MASTER_DATA_SUCCESS",
};

// This defines user setting action types
export const userSettingActionType = {
  GET_LIST_USER_SETTING: "GET_LIST_USER_SETTING",
  GET_LIST_USER_SETTING_SUCCESS: "GET_LIST_USER_SETTING_SUCCESS",
  GET_USER_SETTING_BY_ID: "GET_USER_SETTING_BY_ID",
  UPDATE_USER_SETTING_SUCCESS: "UPDATE_USER_SETTING_SUCCESS",
  GET_LIST_ROLE: "GET_LIST_ROLE",
  GET_LIST_LAB_LOCATION: "GET_LIST_LAB_LOCATION",
  GET_USER_SETTING_SUCCESS: "GET_USER_SETTING_SUCCESS",

  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_PERCENT: "UPLOAD_PERCENT",
  EDIT_SUCCESS: "EDIT_SUCCESS",
  GET_TESTING_REPORTS_SUCCESS: "GET_TESTING_REPORTS_SUCCESS",
}

// This defines testing report action types
export const testingReportActionType = {
  GET_USER_BY_ROLE_ID: "GET_USER_BY_ROLE_ID",
  GET_USER_BY_ROLE_ID_SUCCESS: "GET_USER_BY_ROLE_ID_SUCCESS",
  GET_USER_BY_ROLE_ID_ERROR: "GET_USER_BY_ROLE_ID_ERROR",
  GET_REPORT_DATA: "GET_REPORT_DATA",
  GET_REPORT_DATA_SUCCESS: "GET_REPORT_DATA_SUCCESS",
  GET_REPORT_DATA_ERROR: "GET_REPORT_DATA_ERROR",
  SET_INIT_REPORT_DATA: "SET_INIT_REPORT_DATA",
  CREATE_REPORT_DATA: "CREATE_REPORT_DATA",
  CREATE_REPORT_SUCCESS: "CREATE_REPORT_SUCCESS",
  CREATE_REPORT_ERROR: "CREATE_REPORT_ERROR",
  UPDATE_REPORT_SUCCESS: "UPDATE_REPORT_SUCCESS",
  UPDATE_REPORT_ERROR: "UPDATE_REPORT_ERROR",
  GET_REPORT_VERSIONS_BY_ID: "GET_REPORT_VERSIONS_BY_ID",
  GET_REPORT_VERSION_BY_ID: "GET_REPORT_VERSION_ID",
  GET_TESTING_REPORT_VERSION_SUCCESS: "GET_TESTING_REPORT_VERSION_SUCCESS",
  GET_TESTING_REPORT_VERSION: "GET_TESTING_REPORT_VERSION",
  GET_DATA_TRACKING_PAGE_ERROR: "GET_DATA_TRACKING_PAGE_ERROR",
  GET_DATA_TRACKING_PAGE: "GET_DATA_TRACKING_PAGE",
};

// This defines comment action types
export const commentActionType = {
  GET_LIST_COMMENT: "GET_LIST_COMMENT",
  GET_LIST_COMMENT_SUCCESS: "GET_LIST_COMMENT_SUCCESS",
  GET_LIST_COMMENT_ERROR: "GET_LIST_COMMENT_ERROR",
  GET_DATA_COMMENT_BY_ID: "GET_DATA_COMMENT_BY_ID",
  GET_DATA_COMMENT_BY_ID_SUCCESS: "GET_DATA_COMMENT_BY_ID_SUCCESS",
  GET_DATA_COMMENT_BY_ID_ERROR: "GET_DATA_COMMENT_BY_ID_ERROR",
  UPDATE_DATA_COMMENT: "UPDATE_DATA_COMMENT_PAGE",
  UPDATE_DATA_COMMENT_SUCCESS: "UPDATE_DATA_COMMENT_SUCCESS",
  UPDATE_DATA_COMMENT_ERROR: "UPDATE_DATA_COMMENT_ERROR",

}

// This defines history action types
export const historyActionType = {
  GET_LIST_HISTORY: "GET_LIST_HISTORY",
  GET_LIST_HISTORY_SUCCESS: "GET_LIST_HISTORY_SUCCESS",
  GET_LIST_HISTORY_ERROR: "GET_LIST_HISTORY_ERROR",
  GET_DATA_HISTORY_BY_ID: "GET_DATA_HISTORY_BY_ID",
  GET_DATA_HISTORY_BY_ID_SUCCESS: "GET_DATA_HISTORY_BY_ID_SUCCESS",
  GET_DATA_HISTORY_BY_ID_ERROR: "GET_DATA_HISTORY_BY_ID_ERROR",
  UPDATE_DATA_HISTORY: "UPDATE_DATA_HISTORY_PAGE",
  UPDATE_DATA_HISTORY_SUCCESS: "UPDATE_DATA_HISTORY_SUCCESS",
  UPDATE_DATA_HISTORY_ERROR: "UPDATE_DATA_HISTORY_ERROR",

}

// This defines ags4 action types
export const ags4ActionType = {
  IMPORT_SUCCESS: "IMPORT_SUCCESS",
  CHECK_EXIST_SUCCESS: "CHECK_EXIST_SUCCESS"
}