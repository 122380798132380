// Importing modalActionType from "../actionTypes"
import { modalActionType } from "../actionTypes";

// Initial state of Modal
const initialState = {
  isModalAddBarcodeShow: false, // Setting boolean value to false
  isModalOptionSizeShow: false, // Setting boolean value to false
  isModalReportShow: false, // Setting boolean value to false
  isModalBarcodeShow:false
};

// Declaring the modal reducer function
const modal = (state = initialState, action) => {
  switch (action.type) {
    // Case for Setting Add Barcode Modal Visibility
    case modalActionType.SET_MODAL_ADD_BARCODE_SHOW:
      return {
        ...state, // Spreading previous state object
        isModalAddBarcodeShow: action.payload, // Updating isModalAddBarcodeShow with payload
      }
    // Case for Setting Option Size Modal Visibility
    case modalActionType.SET_MODAL_OPTION_SIZE_SHOW:
      return {
        ...state, // Spreading previous state object
        isModalOptionSizeShow: action.payload, // Updating isModalOptionSizeShow with payload
      }
    // Case for Setting Report Modal Visibility
    case modalActionType.SET_MODAL_REPORT_SHOW:
      return {
        ...state, // Spreading previous state object
        isModalReportShow: action.payload, // Updating isModalReportShow with payload
      }
      case modalActionType.SET_MODAL_BARCODE_SHOW:
      return {
        ...state, // Spreading previous state object
        isModalBarcodeShow: action.payload, // Updating isModalReportShow with payload
      }
      
    // Default case
    default:
      return state; // Return current state if no matched cases are found
  }
};

export default modal; // Exporting modal reducer function as default
