import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css";

/**
 * The customized toast of adit.
 * @param show the flag of displaying.
 * @param message the content message.
 * @param redirect redirect location after display.
 * @returns 
 */
export function SMSYSToast({ show, message, redirect }) {
  // Process use effect when show, message are changed.
  useEffect(() => {
    // If show or message is changed => display toast.
    if (show && message) {
      // Dismiss previous toast.
      toast.dismiss();

      // Show toast like success type.
      toast.success(message, {
        position: "top-center", // Position of displaying toast.
        autoClose: 5000, // auto close after this millisecond.
        hideProgressBar: false, // show process bar.
        closeOnClick: true, // Allow click on this toast.
        pauseOnHover: true, // Allow pose when hover.
        draggable: true, // allow dragable.
        progress: undefined, // disable process.
        // Process for close event => redirect to redirect location.
        onClose: () => redirect && (window.location.href = redirect)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, message]);

  // return html content of toast.
  return (
    <ToastContainer
      position="top-center"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
    />
  );
}