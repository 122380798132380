// Import the required action type from the actionTypes file
import { enquiryActionType } from "../actionTypes";

// Define an initial state object with default values
const initialState = {
  enquiries: [], // array of all enquies
  enquiry: { // object containing details of a single enquiry
    comments: [], // array of comments on an enquiry
    first_name: "",
    last_name: "",
    closest_location: "",
    enquiry_type: "",
    work_email: "",
    project_reference: "",
    sample_id: "",
    subject: ""
  },
  isCreateEnquirySuccess: false, // boolean flag to indicate if enquiry was created successfully
  isCreateCommentEnquirySuccess: false, // boolean flag to indicate if comment on enquiry was created successfully
  isgetEnquiryByIdSuccess: false, // boolean flag to indicate if retrieval of enquiry was successful
  isSolvedEnquirySuccess: false, // boolean flag to indicate if resolution of enquiry was successful
  isGetEnquiriesSuccess: false // boolean flag to indicate if retrieval of all enquiries was successful
}

// Define the main reducer function which takes in the current state and an action parameter
const enquiry = (state = initialState, action) => {

  // Use a switch statement to select which action to take based on the action type received
  switch (action.type) {

    // If the action is GET_LIST_ENQUIRY, return a new state object with the enquiries array updated to the payload received
    case enquiryActionType.GET_LIST_ENQUIRY:
      return {
        ...state,
        enquiries: action.payload,
      }

    // If the action is GET_ENQUIRY_BY_ID, return a new state object with the enquiry object updated to the payload received
    case enquiryActionType.GET_ENQUIRY_BY_ID:
      return {
        ...state,
        enquiry: action.payload,
      }

    // If the action is CREATE_ENQUIRY_SUCCESS, return a new state object with the isCreateEnquirySuccess flag updated to the payload received
    case enquiryActionType.CREATE_ENQUIRY_SUCCESS:
      return {
        ...state,
        isCreateEnquirySuccess: action.payload,
      }

    // If the action is GET_ENQUIRY_BY_ID_SUCCESS, return a new state object with the isgetEnquiryByIdSuccess flag updated to the payload received
    case enquiryActionType.GET_ENQUIRY_BY_ID_SUCCESS:
      return {
        ...state,
        isgetEnquiryByIdSuccess: action.payload,
      }

    // If the action is CREATE_COMMENT_ENQUIRY_SUCCESS, return a new state object with the isCreateCommentEnquirySuccess flag updated to the payload received
    case enquiryActionType.CREATE_COMMENT_ENQUIRY_SUCCESS:
      return {
        ...state,
        isCreateCommentEnquirySuccess: action.payload,
      }

    // If the action is SOLVED_ENQUIRY_SUCCESS, return a new state object with the isSolvedEnquirySuccess flag updated to the payload received
    case enquiryActionType.SOLVED_ENQUIRY_SUCCESS:
      return {
        ...state,
        isSolvedEnquirySuccess: action.payload,
      }

    // If the action is GET_ENQUIRIES_SUCCESS, return a new state object with the isGetEnquiriesSuccess flag updated to the payload received
    case enquiryActionType.GET_ENQUIRIES_SUCCESS:
      return {
        ...state,
        isGetEnquiriesSuccess: action.payload,
      };

    // If the action type doesn't match any of the cases above, simply return the current state object
    default:
      return state
  }
}

// Export the reducer as a default export
export default enquiry;
