// Importing the commentActionType from actionTypes.js file in store.

import { commentActionType } from "store/actionTypes";

// Declaring the initial state of our comments reducer.

const initialState = {
  comments: [],
  comment: {},
  isGetListCommentSuccess: false,
  isGetListCommentError: false,
  isGetDataCommentSuccess: false,
  isGetDataCommentError: false,
  isUpdateCommentSuccess: false,
  isUpdateCommentError: false,
};

// Defining the comments reducer function.

const comment = (state = initialState, action) => {
  switch (action.type) {
    // If the action type is GET_LIST_COMMENT, we update the comments array in the state.
    case commentActionType.GET_LIST_COMMENT:
      return {
        ...state,
        comments: action.payload
      }
    // If the action type is GET_LIST_COMMENT_SUCCESS, we update the isGetListCommentSuccess boolean flag in the state.
    case commentActionType.GET_LIST_COMMENT_SUCCESS:
      return {
        ...state,
        isGetListCommentSuccess: action.payload,
      }
    // If the action type is GET_LIST_COMMENT_ERROR, we update the isGetListCommentError boolean flag in the state.
    case commentActionType.GET_LIST_COMMENT_ERROR:
      return {
        ...state,
        isGetListCommentError: action.payload,
      }
    // If the action type is GET_DATA_COMMENT_BY_ID, we update the comment object in the state.
    case commentActionType.GET_DATA_COMMENT_BY_ID:
      return {
        ...state,
        comment: action.payload
      }
    // If the action type is GET_DATA_COMMENT_BY_ID_SUCCESS, we update the isGetDataCommentSuccess boolean flag in the state.
    case commentActionType.GET_DATA_COMMENT_BY_ID_SUCCESS:
      return {
        ...state,
        isGetDataCommentSuccess: action.payload,
      }
    // If the action type is GET_DATA_COMMENT_BY_ID_ERROR, we update the isGetDataCommentError boolean flag in the state.
    case commentActionType.GET_DATA_COMMENT_BY_ID_ERROR:
      return {
        ...state,
        isGetDataCommentError: action.payload,
      }
    // If the action type is UPDATE_DATA_COMMENT_SUCCESS, we update the isUpdateCommentSuccess boolean flag in the state.
    case commentActionType.UPDATE_DATA_COMMENT_SUCCESS:
      return {
        ...state,
        isUpdateCommentSuccess: action.payload
      }
    // If the action type is UPDATE_DATA_COMMENT_ERROR, we update the isUpdateCommentError boolean flag in the state.
    case commentActionType.UPDATE_DATA_COMMENT_ERROR:
      return {
        ...state,
        isUpdateCommentError: action.payload,
      };
    // If the action type doesn't match any of the cases above, we simply return the unchanged state.
    default:
      return state
  }
}

// Exporting the comments reducer function as default.
export default comment;
