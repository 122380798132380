// Define initial state object with isLoading property set to false.
const initialState = {
  isLoading: false
};

// Define app reducer function with state and action as parameters.
const app = (state = initialState, action) => {
  switch (action.type) {
    // Case for SET_LOADING action type
    case 'SET_LOADING':
      return { isLoading: action.payload };
    // Default fallback case returns the current state.
    default:
      return state;
  }
}

// Exporting app reducer function as default.
export default app
