/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// Configuration object for AWS Amplify
const awsConfig = {
  Auth: {
    // Configuration for Amazon Cognito authentication service
    "aws_project_region": process.env.REACT_APP_COGNITO_REGION, // Region where your Cognito resources are located
    "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION, // Region where your Cognito resources are located
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_USERPOOL_ID, // Pool Id for Cognito User Pool
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_CLIENT_ID // Client id for Cognito User Pool
  },
  oauth: {
    // Configuration for OAuth Providers
    domain: process.env.REACT_APP_COGNITO_DOMAIN, // Domain of your Cognito User Pool
    scope: [
      'email', // Requesting users' email address
      'openid', // OpenID Connect protocol
      'profile', // Requesting users' profile information
      'aws.cognito.signin.user.admin', // Request access to perform admin level operations
    ],
    clientID: process.env.REACT_APP_COGNITO_CLIENT_ID, // Client id for Cognito User Pool
    localRedirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT, // Local path for redirect after sign in
    localRedirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT, // Local path for redirect after sign out
    responseType: 'code', // The type of response to use when requesting tokens from the Authorization Server
  },
  federationTarget: 'COGNITO_USER_POOLS', // Type of federation target
};

// Object that merges only the Auth and oauth keys from awsConfig with customized redirect URIs
const config = {
  ...awsConfig.Auth,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: `${window.location.origin}/login`, // Customized redirect URI after sign in 
    redirectSignOut: `${window.location.origin}/`, // Customized redirect URI after sign out
  }
}

export default config;
