//Importing masterDataActionType from "../actionTypes"
import { masterDataActionType } from "../actionTypes"

//Initial state of masterData
const initialState = {
  masterData: {
    materials: [], //Empty array of materials
    test_groups: [] //Empty array of test_groups
  },
  isGetMasterDataSuccess: false //Setting boolean value to false
}

//Declaring the masterData reducer function
const masterData = (state = initialState, action) => {
  switch (action.type) {
    //Case for setting master data
    case masterDataActionType.SET_MASTER_DATA:
      return {
        ...state, //Spreading previous state object
        masterData: action.payload, //Updating masterData object with payload
      }
    //Case for getting master data success
    case masterDataActionType.GET_MASTER_DATA_SUCCESS:
      return {
        ...state, //Spreading previous state object
        isGetMasterDataSuccess: action.payload, //Updating isGetMasterDataSuccess with payload
      }
    //Default case
    default:
      return state //Return current state if no matched cases are found
  }
}

export default masterData //Exporting masterData reducer function as default
