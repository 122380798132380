// Importing required dependencies
import { addInternalProjectSteps } from "constants/steps";
import { projectActionType } from "../actionTypes";

// Object containing default project data
const defaultProjectData = {
  //customer info
  business_id: "",
  is_internal: "false",
  request_by: "",
  // project info
  organisation_name: "",
  reference: "",
  reference_2: "",
  address: "",
  type_of_hazardous: "Non-hazardous",
  geotechnics_tag: "",
  deltek_project_no: "",
  //options
  schedule_testing_type: "online",

  //sample data
  samples: [
    {
      sample_reference: "",
      bh_test_other: "BH",
      depth_from: "",
      depth_to: "",
      type: "Core",
      material_id: "",
      date_sampled: "",
      sampled_by: "",
      sampling_method: "",
      sampling_method_detail: "",
      description: "",
      sample_status: "pending",
    },
  ],
};

// Initial state object
const initialState = {
  registerProjectData: defaultProjectData,
  currentRegisterStep: addInternalProjectSteps.projectInfo, 
  isRegisterSuccess: false,
  projects: [],
  project: {},
  isRegisterProjectSuccess: false,
  itemsNotExist: false,
  itemsExist: [],
  errorRegisterProject: "",
  isUpdateProjectSuccess: false,
  errorUpdateProject: [],
  isGetProjectByIdSuccess: false,
  isGetProjectsSuccess: false
};

// Reducer function
const project = (state = initialState, action) => {
  switch (action.type) {
    case projectActionType.INIT_REGISTER_PROJECT_DATA:
      // Reset user input to default data and set register success status to false
      return {
        ...state,
        isRegisterSuccess: false,
        registerProjectData: defaultProjectData,
      };
    case projectActionType.REGISTER_PROJECT_DATA:
      // Update user input with the payload data
      return {
        ...state,
        registerProjectData: action.payload,
      };
    case projectActionType.GET_LIST:
      // Update projects list with the fetched data
      return {
        ...state,
        projects: action.payload,
      };
    case projectActionType.GET_PROJECT_BY_ID:
      // Update selected project with the fetched data
      return {
        ...state,
        project: action.payload,
      };
    case projectActionType.PROJECT_REGISTER_SUCCESS:
      // Set register project success status to true/false depending on the payload value
      return {
        ...state,
        isRegisterProjectSuccess: action.payload,
      };
    case projectActionType.ERROR_PROJECT_REGISTER:
      // Update error message with the payload data
      return {
        ...state,
        errorRegisterProject: action.payload,
      };
    case projectActionType.UPDATE_PROJECT_SUCCESS:
      // Set update project success status to true/false depending on the payload value
      return {
        ...state,
        isUpdateProjectSuccess: action.payload,
      };
    case projectActionType.ERROR_UPDATE_PROJECT:
      // Update error message with the payload data
      return {
        ...state,
        errorUpdateProject: action.payload,
      };
    case projectActionType.GET_PROJECT_BY_ID_SUCCESS:
      // Set get project by ID success status to true/false depending on the payload value
      return {
        ...state,
        isGetProjectByIdSuccess: action.payload,
      };
    case projectActionType.SET_CURRENT_REGISTER_STEP:
      // Update the current registration step with the payload data
      return {
        ...state,
        currentRegisterStep: action.payload,
      };
    case projectActionType.GET_PROJECTS_SUCCESS:
      // Set get all projects success status to true/false depending on the payload value
      return {
        ...state,
        isGetProjectsSuccess: action.payload,
      };
    case projectActionType.ITEMS_EXIST:
      // Set get all projects success status to true/false depending on the payload value
      return {
        ...state,
        itemsExist: action.payload,
      };
    case projectActionType.ITEMS_NOT_EXIST:
      // Set get all projects success status to true/false depending on the payload value
      return {
        ...state,
        itemsNotExist: action.payload,
      };
    default:
      // Return the initial state by default
      return state;
  }
};

export default project; // Export the reducer function as a default export
