import axios from "./axios";

// Defining an async arrow function API that takes in different parameters
const API: any = async ({
  url,
  method,
  responseType,
  data,
  cancelToken,
  // baseUrlType,
  params,
  headers,
}) => {
  // Defining the axios request object, which will be used to make the HTTP request
  let axiosRequestObject = {
    method, // HTTP method o
    url, // URL to which the request is made
    // baseURL: getBaseUrl(baseUrlType),
    data, // Data to be sent with the request
    headers, // Headers to be set for the request
    responseType, // Type of response expected
    params, // Parameters to be sent with the request
    ...(cancelToken
      ? {
          cancelToken, // If a cancel token is provided, it is included in this object
        }
      : ""),
  };

  // Making the actual HTTP request using Axios library, and then handling success and error responses
  let request = await axios
    .request(axiosRequestObject)
    .then(handleSuccessRequest) // If the request succeeds, handleSuccessRequest function is called
    .catch(handleErrorRequest); // If the request fails, handleErrorRequest function is called

  return request; // Returning the result back
};

// Function to handle success responses
const handleSuccessRequest = (response) => ({
  status: response.status,
  data: response.data,
});

// Function to handle error responses
const handleErrorRequest = (err) => {
  if (!err.response) {
    // If the response is empty, rejects the promise
    return Promise.reject();
  } else if (err.response?.status === 401) {
    // If the response has a 401 status code, do these things
    localStorage.clear(); // Clearing the local storage
    localStorage.setItem("isTokenExpired", "true"); // Setting an item isTokenExpired as true in local storage
    window.location.href = "/login?continue=" + encodeURIComponent(window.location.href); // Navigating to login page
    return Promise.reject(); // Rejecting the promise
  } else return Promise.reject(err.response);
};
// Exporting the API function
export default API;
