// Importing actionTypes used in this file
import { userSettingActionType } from "../actionTypes"

// Declaring an initial state object with default values
const initialState = {
  userSettings: [],
  userSetting: {},
  isGetUserSettingsSuccess: false,
  isUpdateUserSettingSuccess: false,
  roles: [],
  labLocations: [],
  isGetUserSettingSuccess: false,

  isUploadSuccess: false,
  uploadPercent: 0,
  isTestingReportsSuccess: false,
}

// Creating a reducer function named 'userSetting' with two arguments: (1) state, which takes the initial state as default value, and (2) action.
const userSetting = (state = initialState, action) => {
  // Using switch statement to check for different actionTypes in the passed `action` object and execute corresponding logic
  switch (action.type) {

    // If action.type matches 'userSettingActionType.GET_LIST_USER_SETTING'
    case userSettingActionType.GET_LIST_USER_SETTING:
      // Return a new state object which has all properties of current state object, except userSettings array should have the payload of the matched action.
      return {
        ...state,
        userSettings: action.payload,
      }

    // If action.type matches 'userSettingActionType.GET_LIST_USER_SETTING_SUCCESS'
    case userSettingActionType.GET_LIST_USER_SETTING_SUCCESS:
      // Return a new state object which has all properties of current state object, except isGetUserSettingsSuccess property should have the payload of the matched action.
      return {
        ...state,
        isGetUserSettingsSuccess: action.payload,
      }

    // If action.type matches 'userSettingActionType.GET_USER_SETTING_BY_ID'
    case userSettingActionType.GET_USER_SETTING_BY_ID:
      // Return a new state object which has all properties of current state object, except userSetting property should have the payload of the matched action.
      return {
        ...state,
        userSetting: action.payload,
      }

    // If action.type matches 'userSettingActionType.UPDATE_USER_SETTING_SUCCESS'
    case userSettingActionType.UPDATE_USER_SETTING_SUCCESS:
      // Return a new state object which has all properties of current state object, except isUpdateUserSettingSuccess property should have the payload of the matched action.
      return {
        ...state,
        isUpdateUserSettingSuccess: action.payload,
      }

    // If action.type matches 'userSettingActionType.GET_LIST_ROLE'
    case userSettingActionType.GET_LIST_ROLE:
      // Return a new state object which has all properties of current state object, except roles array should have the payload of the matched action.
      return {
        ...state,
        roles: action.payload,
      }

    // If action.type matches 'userSettingActionType.GET_LIST_LAB_LOCATION'
    case userSettingActionType.GET_LIST_LAB_LOCATION:
      // Return a new state object which has all properties of current state object, except labLocations array should have the payload of the matched action.
      return {
        ...state,
        labLocations: action.payload,
      }

    // If action.type matches 'userSettingActionType.UPLOAD_SUCCESS'
    case userSettingActionType.UPLOAD_SUCCESS:
      // Return a new state object which has all properties of current state object, except isUploadSuccess property should have the payload of the matched action.
      return {
        ...state,
        isUploadSuccess: action.payload
      }

    // If action.type matches 'userSettingActionType.UPLOAD_PERCENT'
    case userSettingActionType.UPLOAD_PERCENT:
      // Return a new state object which has all properties of current state object, except uploadPercent property should have the payload of the matched action.
      return {
        ...state,
        uploadPercent: action.payload
      }

    // If action.type matches 'userSettingActionType.EDIT_SUCCESS'
    case userSettingActionType.EDIT_SUCCESS:
      // Return a new state object which has all properties of current state object, except isEditSuccess property should have the payload of the matched action.
      return {
        ...state,
        isEditSuccess: action.payload
      }

    // If action.type matches 'userSettingActionType.GET_TESTING_REPORTS_SUCCESS'
    case userSettingActionType.GET_TESTING_REPORTS_SUCCESS:
      // Return a new state object which has all properties of current state object, except isTestingReportsSuccess property should have the payload of the matched action.
      return {
        ...state,
        isTestingReportsSuccess: action.payload,
      }

    // If action.type matches 'userSettingActionType.GET_USER_SETTING_SUCCESS'
    case userSettingActionType.GET_USER_SETTING_SUCCESS:
      // Return a new state object which has all properties of current state object, except isGetUserSettingSuccess property should have the payload of the matched action.
      return {
        ...state,
        isGetUserSettingSuccess: action.payload,
      }

    // If none of the above action types match
    default:
      // Return the original state object without any changes
      return state
  }
}

// Exporting the reducer function
export default userSetting
