import React, { Suspense } from "react";
import ROUTES, { RenderRoutes } from "./RenderRoutes";
import SuspenseSpinner from "components/SuspenseFallback";

// Exporting a function called routes that returns a JSX element
export function routes() {
    return (
        // Using the Suspense component from react to show a spinner while the component is being loaded asynchronously
        <Suspense fallback={<SuspenseSpinner />}>
            {/* Rendering the routes using the RenderRoutes component and passing in the ROUTES object */}
            <RenderRoutes routes={ROUTES} />
        </Suspense>
    );
}