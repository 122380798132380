// Importing materialActionType from "../actionTypes"
import { materialActionType } from "../actionTypes";

// Initial State of the Material
const initialState = {
  materials: [], // Empty Array of Materials
  isAddMaterialSuccess: "", // Set String "" to variable "isAddMaterialSuccess"
  isUpdateMaterialSuccess: false, // Set boolean value to false
  errorMaterial: "", // Set String "" to variable "errorMaterial"
  isDeleteMaterialSuccess: false, // Set boolean value to false
  isOrderMaterialSuccess: false, // Set boolean value to false
  isGetMaterialsSuccess: false, // Set boolean value to false
}

// Declare Material reducer function
const material = (state = initialState, action) => {
  switch (action.type) {
    // Case for getting list of materials
    case materialActionType.GET_LIST_MATERIAL:
      return {
        ...state, // Spreading previous state object
        materials: action.payload, // Updating the materials array with payload
      }
    // Case for adding material success
    case materialActionType.ADD_MATERIAL_SUCCESS:
      return {
        ...state, // Spreading previous state object
        isAddMaterialSuccess: action.payload // Updating isAddMaterialSuccess with payload
      }
    // Case for updating material success
    case materialActionType.UPDATE_MATERIAL_SUCCESS:
      return {
        ...state, // Spreading previous state object
        isUpdateMaterialSuccess: action.payload, // Updating isUpdateMaterialSuccess with payload
      }
    // Case for Error in material
    case materialActionType.ERROR_MATERIAL:
      return {
        ...state, // Spreading previous state object
        errorMaterial: action.payload, // Updating errorMaterial with payload
      }
    // Case for deleting material success
    case materialActionType.DELETE_MATERIAL_SUCCESS:
      return {
        ...state, // Spreading previous state object
        isDeleteMaterialSuccess: action.payload, // Updating isDeleteMaterialSuccess with payload
      }
    // Case for ordering material success
    case materialActionType.ORDER_MATERIAL_SUCCESS:
      return {
        ...state, // Spreading previous state object
        isUpdateMaterialSuccess: action.payload, // Updating isUpdateMaterialSuccess with payload
      }
    // Case for clearing error message in material
    case materialActionType.CLEAR_ERROR_MATERIAL_MESSAGE:
      return {
        ...state, // Spreading previous state object
        errorMaterial: '', // Setting the error message to empty string
      }
    // Case for getting materials success
    case materialActionType.GET_MATERIALS_SUCCESS:
      return {
        ...state, // Spreading previous state object
        isGetMaterialsSuccess: action.payload, // Updating isGetMaterialsSuccess with payload
      }
    // Default Case
    default:
      return state // Return current state if no matched cases are found
  }
}

export default material // Exporting Material reducer function as default
