import { testGroupActionType } from "../actionTypes";

const initialState = {
  testGroups: [],
  isAddTestGroupSuccess: false,
  isGetListSuccess: false,
  errorAddTestGroup: "",
  isUpdateTestGroupSuccess: false,
  errorTestGroup: "",
  isDeleteTestGroupSuccess: false,
  currentRow: null,
};

const testGroup = (state = initialState, action) => {
  switch (action.type) {
    // Action to get list of test groups.
    case testGroupActionType.GET_LIST:
      return {
        ...state,
        testGroups: action.payload,
      };
    // Action to get list of test groups successfully.
    case testGroupActionType.GET_LIST_SUCCESS:
      return {
        ...state,
        isGetListSuccess: action.payload,
      };
    // Action to add a test group successfully.
    case testGroupActionType.ADD_TEST_GROUP_SUCCESS:
      return {
        ...state,
        isAddTestGroupSuccess: action.payload,
      };
    // Action for error while adding a test group.
    case testGroupActionType.ERROR_ADD_TEST_GROUP:
      return {
        ...state,
        errorAddTestGroup: action.payload,
      };
    // Action to update a test group successfully.
    case testGroupActionType.UPDATE_TEST_GROUP_SUCCESS:
      return {
        ...state,
        isUpdateTestGroupSuccess: action.payload,
      };
    // Action for error in updating a test group.
    case testGroupActionType.ERROR_TEST_GROUP:
      return {
        ...state,
        errorTestGroup: action.payload,
      };
    // Action to delete a test group successfully.
    case testGroupActionType.DELETE_TEST_GROUP_SUCCESS:
      return {
        ...state,
        isDeleteTestGroupSuccess: action.payload,
      };
    // Action to set expand row.
    case testGroupActionType.SET_EXPAND_ROW:
      return {
        ...state,
        currentRow: action.payload,
      };
    default:
      return state;
  }
};

export default testGroup;
