// Importing required modules.
import { uploadActionType } from "store/actionTypes"

// Setting initial state values.
const initialState = {
  files: [],
  isUploadSuccess: false,
  isEditSuccess: false,
  isDeleteSuccess: false,
  uploadPercent: 0,
  isTestingReportsSuccess: false,
};

// Defining upload reducer as a function with two parameters.
const upload = (state = initialState, action) => {

  // Using switch case to switch between different actions.
  switch (action.type) {

    // If action type is GET_LIST then return updated state.
    case uploadActionType.GET_LIST:
      return {
        ...state,
        files: action.payload
      }

    // If action type is UPLOAD_SUCCESS then return updated state.
    case uploadActionType.UPLOAD_SUCCESS:
      return {
        ...state,
        isUploadSuccess: action.payload
      }

    // If action type is UPLOAD_PERCENT then return updated state.
    case uploadActionType.UPLOAD_PERCENT:
      return {
        ...state,
        uploadPercent: action.payload
      }

    // If action type is EDIT_SUCCESS then return updated state.
    case uploadActionType.EDIT_SUCCESS:
      return {
        ...state,
        isEditSuccess: action.payload
      }

    // If action type is DELETE_SUCCESS then return updated state.
    case uploadActionType.DELETE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: action.payload
      }

    // If action type is GET_TESTING_REPORTS_SUCCESS then return updated state.
    case uploadActionType.GET_TESTING_REPORTS_SUCCESS:
      return {
        ...state,
        isTestingReportsSuccess: action.payload,
      }

    // If no action type matches, default state is returned.
    default:
      return state
  }
}

// Exporting upload as the default module for external use.
export default upload
