// Import necessary Redux hooks and store
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import store from './store';

// Define types for AppDispatch and RootState based on store type
type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;

/**
 * Create a custom useDispatch hook that returns an instance of AppDispatch type.
 * This hook should be used throughout the app instead of useDispatch.
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * Create a custom useSelector hook that takes RootState as a generic argument and returns
 * root state from the store. This hook should be used throughout the app instead of useSelector.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
