import { Router } from "react-router-dom";
import Routes from './routes'

// Import the Redux store and Provider component
import store from './store'
import { Provider } from 'react-redux'

// Import the history helper function
import { history } from 'helpers/common';

// Import CSS files
import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/css/app.css"
import "./App.css"

// Import Amplify and configuration object
import Amplify from 'aws-amplify'
import config from './aws_export'

// Configure Amplify with the provided configuration object
Amplify.configure(config)

// Declare the main App component
const App = () => {
  // Render the application, wrapping it with the Redux Provider and React Router
  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  )
}

// Export the App component as the default export for this module
export default App;
