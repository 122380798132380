// Importing action types from store
import { testingRequestActionType } from "store/actionTypes";

// Initial State of Reducer
const initialState = {
  testingRequests: [],
  testingDetail: [],
  isCreateTestingRequestSuccess: false,
  isGetTestingRequestSuccess: false,
  isUpdateTestingRequestSuccess: false,
  unscheduledSamples: [],
  isGetUnscheduledSamplesSuccess: false,
  isGetTestingRequestsSuccess: false,
  isChangeStatusSampleSuccess: false,
  isResetStatusSuccess: false,
};

// Reducer Function
const testingRequest = (state = initialState, action) => {
  switch (action.type) {

    // Update state based on type GET_TESTING_REQUESTS
    case testingRequestActionType.GET_TESTING_REQUESTS:
      return {
        ...state,
        testingRequests: action.payload
      }

    // Update state based on type CREATE_TESTING_REQUEST_SUCCESS
    case testingRequestActionType.CREATE_TESTING_REQUEST_SUCCESS:
      return {
        ...state,
        isCreateTestingRequestSuccess: action.payload
      }

    // Update state based on type GET_TESTING_REQUEST
    case testingRequestActionType.GET_TESTING_REQUEST:
      return {
        ...state,
        testingDetail: action.payload
      }

    // Update state based on type GET_TESTING_REQUEST_SUCCESS
    case testingRequestActionType.GET_TESTING_REQUEST_SUCCESS:
      return {
        ...state,
        isGetTestingRequestSuccess: action.payload,
      }

    // Update state based on type UPDATE_TESTING_REQUEST_SUCCESS
    case testingRequestActionType.UPDATE_TESTING_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdateTestingRequestSuccess: action.payload
      }

    // Update state based on type GET_UNSCHEDULED_SAMPLES
    case testingRequestActionType.GET_UNSCHEDULED_SAMPLES:
      return {
        ...state,
        unscheduledSamples: action.payload
      }

    // Update state based on type GET_UNSCHEDULED_SAMPLES_SUCCESS
    case testingRequestActionType.GET_UNSCHEDULED_SAMPLES_SUCCESS:
      return {
        ...state,
        isGetUnscheduledSamplesSuccess: action.payload,
      }

    // Update state based on type GET_TESTING_REQUESTS_SUCCESS
    case testingRequestActionType.GET_TESTING_REQUESTS_SUCCESS:
      return {
        ...state,
        isGetTestingRequestsSuccess: action.payload,
      }

    // Update state based on type CHANGE_STATUS_SAMPLE_SUCCESS
    case testingRequestActionType.CHANGE_STATUS_SAMPLE_SUCCESS:
      return {
        ...state,
        isChangeStatusSampleSuccess: action.payload
      }
    
    // Update state based on type RESET_STATUS_SUCCESS
    case testingRequestActionType.RESET_STATUS_SUCCESS:
      return {
        ...state,
        isResetStatusSuccess: action.payload
      }

    // Return the initial state if action.type is not recognised
    default:
      return state
  }
}

// Exporting Reducer Function
export default testingRequest
