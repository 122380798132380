import { testingReportActionType } from "store/actionTypes";

const defaultReportData = {};
const initialState = {
  userList: [],
  isGetUserListSuccess: false,
  isGetUserListError: "",
  reportData: {},
  isGetReportDataSuccess: false,
  isGetReportDataError: "",
  isCreateReportSuccess: false,
  isCreateReportError: "",
  isUpdateReportSuccess: false,
  isUpdateReportError: "",
  reportVersions: [],
  reportVersionHistory: {},
};

const testingReport = (state = initialState, action) => {
  switch (action.type) {
    // Action to set default report data.
    case testingReportActionType.SET_INIT_REPORT_DATA:
      return {
        ...state,
        isRegisterSuccess: false,
        reportData: defaultReportData,
      };
    // Action to get user list by role ID.
    case testingReportActionType.GET_USER_BY_ROLE_ID:
      return {
        ...state,
        userList: action.payload,
      };
    // Action for successful retrieval of user list by role ID.
    case testingReportActionType.GET_USER_BY_ROLE_ID_SUCCESS:
      return {
        ...state,
        isGetUserListSuccess: action.payload,
      };
    // Action for error in getting user list by role ID.
    case testingReportActionType.GET_USER_BY_ROLE_ID_ERROR:
      return {
        ...state,
        isGetUserListError: action.payload,
      };
    // Action to get report data.
    case testingReportActionType.GET_REPORT_DATA:
      return {
        ...state,
        reportData: action.payload,
      };
    // Action for successfully retrieving report data.
    case testingReportActionType.GET_REPORT_DATA_SUCCESS:
      return {
        ...state,
        isGetReportDataSuccess: action.payload,
      };
    // Action for error in getting report data.
    case testingReportActionType.GET_REPORT_DATA_ERROR:
      return {
        ...state,
        isGetReportDataError: action.payload,
      };
    // Action for successfully creating a new report.
    case testingReportActionType.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        isCreateReportSuccess: action.payload,
      };
    // Action for error in creating a new report.
    case testingReportActionType.CREATE_REPORT_ERROR:
      return {
        ...state,
        isCreateReportError: action.payload,
      };
    // Action for successfully updating an existing report.
    case testingReportActionType.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        isUpdateReportSuccess: action.payload,
      };
    // Action for error in updating an existing report.
    case testingReportActionType.UPDATE_REPORT_ERROR:
      return {
        ...state,
        isUpdateReportError: action.payload,
      };
    case testingReportActionType.GET_REPORT_VERSIONS_BY_ID:
      return {
        ...state,
        reportVersions: action.payload,
      }
    case testingReportActionType.GET_REPORT_VERSION_BY_ID:
      return {
        ...state,
        reportVersionHistory: action.payload,
      }
    default:
      return state;
  }
};

export default testingReport;
