// Importing action types used in laboratories reducer.
import { laboratoriesActionType } from "store/actionTypes";

// Initial state of laboratories.
const initialState = {
  // An empty array to hold internal labs.
  listLabInternal: [],
  // A flag to check if getting list of internal labs is successful or not.
  isGetListLabInternalSuccess: false,
  // An empty array to hold external labs.
  listLabExternal: [],
  // A flag to check if getting list of external labs is successful or not.
  isGetListLabExternalSuccess: false,
  // An empty array to hold users by location data.
  listUserByLocation: [],
  // A flag to check if getting list of user by location is successful or not.
  isGetListUserByLocationSuccess: false,
};

/**
 * This code exports a reducer function 'laboratories' that takes two parameters 
 * - the current state and an action to perform. Inside the reducer function,
 * there is a switch case that returns the updated state according to the action performed.
 * The reducer checks for different actions like getting data from API,
 * and returning success or error flags based on the action performed.
 * Finally, the reducer returns the updated state.
 * The initialState object contains an empty array for listLabInternal and listLabExternal 
 * as well as some flags initialized to false values.
 * @param state 
 * @param action 
 * @returns 
 */
// Laboratories reducer function definition which accepts previous state and an action to perform.
const laboratories = (state = initialState, action) => {
  switch (action.type) {
    // Get list of internal laboratories from API.
    case laboratoriesActionType.GET_LIST_LABORATORY_INTERNAL:
      // Return new state object after making a copy of previous state object and updating listLabInternal array with payload received from API.
      return {
        ...state,
        listLabInternal: action.payload
      }
    // When getting list of internal laboratories from API is successful.
    case laboratoriesActionType.GET_LIST_LABORATORY_INTERNAL_SUCCESS:
      // Return new state object after making a copy of previous state object and setting isGetListLabInternalSuccess flag to true.
      return {
        ...state,
        isGetListLabInternalSuccess: action.payload,
      }
    // Get list of external laboratories from API.
    case laboratoriesActionType.GET_LIST_LABORATORY_EXTERNAL:
      // Return new state object after making a copy of previous state object and updating listLabExternal array with payload received from API.
      return {
        ...state,
        listLabExternal: action.payload
      }
    // When getting list of external laboratories from API is successful.
    case laboratoriesActionType.GET_LIST_LABORATORY_EXTERNAL_SUCCESS:
      // Return new state object after making a copy of previous state object and setting isGetListLabExternalSuccess flag to true.
      return {
        ...state,
        isGetListLabExternalSuccess: action.payload,
      }
    // Get list of users by location from API.
    case laboratoriesActionType.GET_LIST_USER_BY_LOCATION:
      // Return new state object after making a copy of previous state object and updating listUserByLocation array with payload received from API.
      return {
        ...state,
        listUserByLocation: action.payload,
      }
    // When getting list of users by location from API is successful.
    case laboratoriesActionType.GET_LIST_USER_BY_LOCATION_SUCCESS:
      // Return new state object after making a copy of previous state object and setting isGetListUserByLocationSuccess flag to true.
      return {
        ...state,
        isGetListUserByLocationSuccess: action.payload,
      }
    // If action type doesn't match any of the above cases, return the previous state object.
    default:
      return state
  }
}
// Exporting the Laboratories reducer function as default.
export default laboratories;
