// Importing required modules.
import { trackingActionType } from "store/actionTypes";

// Setting initial state values.
const initialState = {
  listTracking: [],
  dataTrackingPage: {},
  dataTestingReport: {},
  isGetListTrackingPageSuccess: false,
  isGetListTrackingPageError: false,
  isGetDataTrackingPageSuccess: false,
  isGetDataTrackingPageError: false,
  isUpdateTrackingPageSuccess: false,
  isDeleteTestTypeSuccess: false,
  isGetDataTestingReportSuccess: false,
};

// Defining tracking reducer as a function with two parameters.
const tracking = (state = initialState, action) => {

  // Using switch case to switch between different actions.
  switch (action.type) {

    // If action type is GET_LIST_TRACKING then return updated state.
    case trackingActionType.GET_LIST_TRACKING:
      return {
        ...state,
        listTracking: action.payload
      }

    // If action type is GET_LIST_TRACKING_SUCCESS then return updated state.
    case trackingActionType.GET_LIST_TRACKING_SUCCESS:
      return {
        ...state,
        isGetListTrackingPageSuccess: action.payload,
      }

    // If action type is GET_LIST_TRACKING_ERROR then return updated state.
    case trackingActionType.GET_LIST_TRACKING_ERROR:
      return {
        ...state,
        isGetListTrackingPageError: action.payload,
      }

    // If action type is GET_DATA_TRACKING_PAGE then return updated state.
    case trackingActionType.GET_DATA_TRACKING_PAGE:
      return {
        ...state,
        dataTrackingPage: action.payload
      }

    // If action type is GET_DATA_TRACKING_PAGE_SUCCESS then return updated state.
    case trackingActionType.GET_DATA_TRACKING_PAGE_SUCCESS:
      return {
        ...state,
        isGetDataTrackingPageSuccess: action.payload,
      }

    // If action type is GET_DATA_TRACKING_PAGE_ERROR then return updated state.
    case trackingActionType.GET_DATA_TRACKING_PAGE_ERROR:
      return {
        ...state,
        isGetDataTrackingPageError: action.payload,
      }

    // If action type is UPDATE_DATA_TRACKING_PAGE_SUCCESS then return updated state.
    case trackingActionType.UPDATE_DATA_TRACKING_PAGE_SUCCESS:
      return {
        ...state,
        isUpdateTrackingPageSuccess: action.payload
      }

    // If action type is DELETE_TEST_TYPE_SUCCESS then return updated state.
    case trackingActionType.DELETE_TEST_TYPE_SUCCESS:
      return {
        ...state,
        isDeleteTestTypeSuccess: action.payload
      }

    // If action type is GET_DATA_TESTING_REPORT then return updated state.
    case trackingActionType.GET_DATA_TESTING_REPORT:
      return {
        ...state,
        dataTestingReport: action.payload
      }

    // If action type is GET_DATA_TESTING_REPORT_SUCCESS then return updated state.
    case trackingActionType.GET_DATA_TESTING_REPORT_SUCCESS:
      return {
        ...state,
        isGetDataTestingReportSuccess: action.payload,
      }

    // If no action type matches, default state is returned.
    default:
      return state
  }
}

// Exporting tracking as the default module for external use.
export default tracking
